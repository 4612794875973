export const isBrowser = typeof document !== "undefined";

export function getDimensions(dimensions, maxWidth = 50) {
  const scale = dimensions.width / maxWidth;
  return {
    width: Math.round(dimensions.width / scale),
    height: Math.round(dimensions.height / scale),
  };
}

export function getTicketImageFileName(githubUserName) {
  return `${githubUserName}-react-day-ticket.png`;
}

export function getTicketImageUrl(githubUserName) {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://tickets2022.reactday.in"
      : "https://tickets2022-staging.reactday.in";
  return `${baseUrl}/${getTicketImageFileName(githubUserName)}`;
}

export async function apiPost({ url, data, onSuccess, onError }) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Bad response");
      }
      return res.json();
    })
    .then((data) => onSuccess && onSuccess(data))
    .catch((e) => onError && onError(e));
}

export async function apiGet({ url, onSuccess, onError }) {
  return fetch(url)
    .then((res) => {
      if (!res.ok) {
        throw new Error("Bad response");
      }
      return res.json();
    })
    .then((data) => onSuccess && onSuccess(data))
    .catch((e) => onError && onError(e));
}
