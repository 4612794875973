import classNames from "classnames";
import Image from "next/image";
import { getDimensions } from "../../utils";
import patterns from "../../data/patterns";

function Pattern({
  id,
  src,
  layout,
  width,
  height,
  className,
  style,
  position,
  lgOnly,
  loading,
  priority,
}) {
  const img = (
    <Image
      alt={id}
      className={className}
      style={style}
      src={src}
      width={width}
      height={height}
      layout={layout}
      loading={loading}
      priority={priority}
    />
  );

  if (position) {
    return (
      <div
        className={classNames("absolute", {
          "hidden md:block": lgOnly,
          "block md:hidden": !lgOnly,
        })}
        style={position}
      >
        {img}
      </div>
    );
  }
  return img;
}

export default function PatternById(props) {
  const pattern = patterns.find((p) => p.id === props.id);
  const dimensions = getDimensions(
    { width: pattern.width, height: pattern.height },
    props.width
  );
  return (
    <Pattern
      {...props}
      src={pattern.src}
      width={dimensions.width}
      height={dimensions.height}
    />
  );
}
