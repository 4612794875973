import React from "react";
import Link from "next/link";
import classNames from "classnames";
import Container from "./ui/Container";
import { TwitterIcon, LinkedinIcon, YouTubeIcon } from "./Icons";
import DiscordIcon from "./Icons/DiscordIcon";
import PatternById from "./ui/PatternById";

function SocialLinks() {
  return (
    <div className="flex">
      <TwitterIcon url="https://twitter.com/ReactDayIn" />
      <LinkedinIcon url="https://www.linkedin.com/company/react-day" />
      <YouTubeIcon url="https://www.youtube.com/reactify_in" />
      <a href="https://discord.com/invite/NP9qKqjw3Y">
        <DiscordIcon className="text-2xl m-1" />
      </a>
    </div>
  );
}

function Divider() {
  return <div className="px-2 text-2xl leading-none">|</div>;
}

function FooterLinks() {
  const linkClassName = "cursor-pointer text-lg font-bold hover:underline";
  return (
    <div className="flex">
      <Link href="/jobs">
        <a className={linkClassName}>Jobs</a>
      </Link>
      <Divider />
      <Link href="/code-of-conduct">
        <a className={linkClassName}>Code of conduct</a>
      </Link>
    </div>
  );
}

function PastEditions() {
  const linkClassName = "cursor-pointer hover:underline text-lg font-bold";
  return (
    <div className="flex">
      <a href="https://2021.reactday.in/" className={linkClassName}>
        React Day Bangalore 2021
      </a>
    </div>
  );
}

function FooterLogo() {
  return (
    <Link href="/">
      <a>
        <img src={require("../images/Logos/logo.svg")} />
      </a>
    </Link>
  );
}

function Copyright() {
  return (
    <div className="text-lg mx-10 md:mx-0 text-center">
      &copy; 2022 React Day Bangalore. All rights reserved
    </div>
  );
}

export default function Footer() {
  return (
    <Container
      className="relative py-2 md:py-10"
      backgroundClassName="bg-gray-100"
    >
      <PatternById
        id="square"
        width={48}
        position={{ top: "20%", left: "-5%", transform: "rotate(35deg)" }}
      />
      <PatternById
        id="triangle"
        width={40}
        position={{
          top: "15%",
          right: "5%",
          transform: "rotate(-35deg)",
        }}
      />
      <div className="flex flex-col md:flex-row md:justify-between pt-12 md:pt-2">
        <div className="hidden md:block">
          <FooterLogo />
        </div>
        <div className="flex flex-col items-center md:items-end">
          <SocialLinks />
          <FooterLinks />
          <PastEditions />
          <div className="block md:hidden py-5">
            <FooterLogo />
          </div>
          <Copyright />
        </div>
      </div>
    </Container>
  );
}
