import React from "react";
import classNames from "classnames";

interface IProps {
  id?: string;
  backgroundClassName?: string;
  className?: string;
  children: React.ReactNode;
}

export default function Container({
  id,
  backgroundClassName,
  className,
  children,
}: IProps) {
  return (
    <div id={id} className={backgroundClassName}>
      <div
        className={classNames(
          "max-w-screen-xl mx-auto px-4 md:px-8",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
