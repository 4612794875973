export default [
  {
    id: "square",
    src: require("../images/patterns/p_sq.png"),
    width: 402,
    height: 413,
  },
  {
    id: "triangle",
    src: require("../images/patterns/p_tri.png"),
    height: 550,
    width: 487,
  },
  {
    id: "star",
    src: require("../images/patterns/p_react.png"),
    height: 622,
    width: 708,
  },
  {
    id: "cross",
    src: require("../images/patterns/p_cross.png"),
    height: 608,
    width: 610,
  },
  {
    id: "cylinder",
    src: require("../images/patterns/p_cyl.png"),
    height: 183,
    width: 785,
  },
  {
    id: "sponsorShapes",
    src: require("../images/patterns/sponsor-shapes.png"),
    height: 197,
    width: 252,
  },
  {
    id: "halfCircle",
    src: require("../images/patterns/half_circle.png"),
    height: 452,
    width: 199,
  },
  {
    id: "overviewLeft",
    src: require("../images/patterns/overview-left.png"),
    height: 247,
    width: 255,
  },
  {
    id: "overviewRight",
    src: require("../images/patterns/overview-right.png"),
    height: 494,
    width: 431,
  },
  {
    id: "bangalore",
    src: require("../images/patterns/bangalore.png"),
    height: 247,
    width: 335,
  },

  {
    id: "mobileHeroShapes",
    src: require("../images/patterns/mobile-hero-shapes.png"),
    height: 216,
    width: 99,
  },
  {
    id: "speakerShapes",
    src: require("../images/patterns/speaker-shapes.png"),
    height: 159,
    width: 522,
  },
  {
    id: "mendu",
    src: require("../images/patterns/mendu.png"),
    height: 1053,
    width: 1398,
  },
  {
    id: "coffee",
    src: require("../images/patterns/coffee.png"),
    height: 1751,
    width: 1451,
  },
  {
    id: "footerShapes",
    src: require("../images/patterns/footer-shapes.png"),
    height: 608,
    width: 686,
  },
];
