import React, { useState, useEffect } from "react";
import Link from "next/link";
import logo from "../images/Logos/Logo_Dark/Logo.svg";
import FullLogo from "../images/Logos/logo.svg";
import FixedNavBar from "./FixedNavBar";
import MobileMenu from "./MobileMenu";
import menu from "../images/icons/menu.svg";

const SCROLL_OFFSET = 80;

export default function NavBar() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showFixedNav, setShowFixedNav] = useState(false);
  function handleScroll(e) {
    if (showFixedNav && window.scrollY < SCROLL_OFFSET) {
      setShowFixedNav(false);
    }
    if (!showFixedNav && window.scrollY > SCROLL_OFFSET) {
      setShowFixedNav(true);
    }
  }

  // TBD: Is there a better way than onscroll event handler to handle fixed nav
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  function openModal() {
    setShowFixedNav(false);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    handleScroll();
  }
  const linkClassName = "cursor-pointer hover:underline text-2xl mx-2 lg:mx-5";
  return (
    <>
      {showFixedNav && (
        <FixedNavBar
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      )}
      <div className="px-6 py-4 md:px-20">
        <div className="flex h-20 md:h-24">
          <div className="flex w-full justify-between items-center md:hidden">
            <Link href="/">
              <a className={linkClassName}>
                <img className="h-16" src={FullLogo} alt="logo" />
              </a>
            </Link>
            <MobileMenu
              menu={menu}
              modalIsOpen={modalIsOpen}
              openModal={openModal}
              closeModal={closeModal}
            />
          </div>
          <div className="hidden md:flex flex-1 items-center justify-center">
            <Link href="/#overview">
              <a className={linkClassName}>Overview</a>
            </Link>
            <Link href="/#speakers">
              <a className={linkClassName}>Speakers</a>
            </Link>
            <Link href="/#schedule">
              <a className={linkClassName}>Schedule</a>
            </Link>
            <Link href="/">
              <a className={linkClassName}>
                <img className="h-10" src={logo} alt="logo" />
              </a>
            </Link>
            {/* <Link href="/#emcees">
              <a className={linkClassName}>Emcees</a>
            </Link> */}
            <Link href="/#sponsors">
              <a className={linkClassName}>Sponsors</a>
            </Link>
            <Link href="/jobs">
              <a className={linkClassName}>Jobs</a>
            </Link>
            <Link href="/#organizers">
              <a className={linkClassName}>Organizers</a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
