import React from "react";
import Head from "next/head";
import classNames from "classnames";
import { useRouter } from "next/router";
import NavBar from "~/components/NavBar";
import Footer from "~/components/Footer";
import ornaments from "~/images/patterns/ornaments.png";

// if (typeof window !== "undefined") {
//   // eslint-disable-next-line global-require
//   require("smooth-scroll")('a[href*="#"]');
// }

interface IProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

const Layout = ({
  children,
  title = "React Day Bangalore",
  className,
}: IProps) => {
  const router = useRouter();
  const isHomepage = router.route === "/";
  const isTicketsPage =
    router.route.startsWith("/tickets/") ||
    router.route.startsWith("/tickets-2022/");
  const url = `${process.env.NEXT_PUBLIC_SITE_ORIGIN}${router.asPath}`;
  const description =
    "React conference dedicated to bringing 2000+ react developers together.";
  const cardUrl = `${process.env.NEXT_PUBLIC_SITE_ORIGIN}/card2022.png`;
  return (
    <div className={classNames("bg-gray-100", className)}>
      <Head>
        <title>{title}</title>
        {!isTicketsPage && (
          <>
            <meta property="og:title" content={title} />
            <meta property="og:url" content={url} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@ReactDayIn" />
            <meta name="twitter:creator" content="@ReactDayIn" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta name="twitter:image" content={cardUrl} />
            <meta property="og:image" content={cardUrl} />
          </>
        )}
        <link rel="icon" href="/favicon.ico" />
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        ></script>
      </Head>
      {isHomepage ? (
        <img
          className="hidden lg:block absolute top-0 right-0 w-1/5"
          src={ornaments}
          alt="green circle"
        />
      ) : null}
      <NavBar />
      <div className="min-h-screen flex flex-col">
        <div className="flex-1">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
