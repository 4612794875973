import React from "react";
import twitterIcon from "../../images/icons/twitter.svg";
import linkedinIcon from "../../images/icons/linkedin.svg";
import githubIcon from "../../images/icons/github.svg";
import downloadIcon from "../../images/icons/download.svg";
import youTubeIcon from "../../images/icons/youtube.svg";

export default function Icon({ src, url, alt }) {
  return (
    <a href={url}>
      <img className="m-1" src={src} alt={alt} />
    </a>
  );
}

export function TwitterIcon({ url }) {
  return <Icon alt="twitter icon" url={url} src={twitterIcon} />;
}

export function LinkedinIcon({ url }) {
  return <Icon alt="linkedin icon" url={url} src={linkedinIcon} />;
}

export function GithubIcon({ url }) {
  return <Icon alt="github icon" url={url} src={githubIcon} />;
}

export function YouTubeIcon({ url }) {
  return <Icon alt="YouTube icon" url={url} src={youTubeIcon} />;
}

export function DownloadIcon({ url }) {
  return <Icon alt="download icon" url={url} src={downloadIcon} />;
}
